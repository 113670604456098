import React from 'react'
import Navbar from '../Navbar/Navbar'
import { Grid } from '@material-ui/core'
import ProjectsArray from './ProjectsArray'
import useStyles from './styles'
import Project from './Project/Project'
import './styles.css'

const Projects = () => {
  const classes = useStyles()

  return (
    <div className='projects'> 
        <Navbar prop={'/'} name={"Home"} />
        <div style={{margin:20}}>
        <Grid className={classes.mainContainer} container alignContent='center' spacing={5}>
            {
              ProjectsArray.map((post, i) => (
                    <Grid key={i} item xs={12} sm={6}>
                        <Project post={post}/>
                    </Grid>
                ))
            }
        </Grid>
        </div>
    </div>
  )
}

export default Projects

