import React from "react";
import Navbar from "../Navbar/Navbar";
import Video from "../../videos/video2.mp4";
import myLogo from "../images/MyLogo.png";
import { isMobile, MobileView } from "react-device-detect";
import "./style.css";
import TypeWriter from "./ TypeWriter";

const Home = () => {
  const getRandomInt = (max) => {
    return Math.floor(Math.random() * Math.floor(max));
  };
  let random = getRandomInt(30);

  if (isMobile || MobileView) {
    return (
      <div class="home">
        <Navbar prop={"/projects"} name={"Projects"} />
        <div class="wrapper">
          <div class="item">
            <div class="polaroid">
              <img alt="logo" src={myLogo} />
              <TypeWriter
                text={
                  random % 5
                    ? "Welcome to my personal site"
                    : "Luckily the weather is on our side today!"
                }
              />
              <TypeWriter text={"The links to my work and contact are above"} />
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="home2">
      <Navbar prop={"/projects"} name={"Projects"} />
      <video
        autoPlay
        loop
        controls={false}
        muted
        style={{
          position: "absolute",
          width: "100%",
          left: "50%",
          top: "50%",
          height: "100%",
          objectFit: "cover",
          transform: "translate(-50%, -50%)",
          zIndex: "-1",
        }}
      >
        <source src={Video} type="video/mp4" />
      </video>

      <div class="wrapper home2">
        <div class="item">
          <div class="polaroid">
            <img alt="logo" src={myLogo} />
            <TypeWriter
              text={
                random % 5
                  ? "Welcome to my personal site"
                  : "Luckily the weather is on our side today!"
              }
            />
            <TypeWriter text={"The links to my work and contact are above"} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
