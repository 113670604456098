import React from 'react'
import {Nav, NavbarContainer, NavLogo, NavLogos} from './NavbarStyles'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { fas } from '@fortawesome/free-solid-svg-icons'
library.add(fab, fas)

const Navbar = ({ prop, name }) => {
  console.log('prop:', name)
  return (
    <div style={{postion:'relative'}}>
      <Nav>
        <NavbarContainer>
          <NavLogo to={prop}>{name === 'Home' ? <FontAwesomeIcon style={{fontSize:40 }}  icon={['fas', 'home']} /> : <FontAwesomeIcon style={{fontSize:40}}  icon={['fas', 'briefcase']} />}</NavLogo>
          <div>
            <NavLogos className='linked'  href='https://github.com/JeffAJohnson22'><FontAwesomeIcon style={{fontSize:40}} icon={['fab', 'github-square']} /></NavLogos>
          </div>
          <div>
            <NavLogos  className='linked'  href ='https://www.linkedin.com/in/jeffjohnsonadr/'><FontAwesomeIcon style={{fontSize:40}}  icon={['fab', 'linkedin']} /></NavLogos>
          </div>
        </NavbarContainer>
      </Nav>
    </div>
  )
}

export default Navbar
