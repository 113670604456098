import React from "react";
import {
  Card,
  CardActions,
  CardMedia,
  Button,
  Typography,
} from "@material-ui/core";
import useStyles from "./styles";

const Project = ({ post: { img, name, url, synopsis, used, video } }) => {
  const classes = useStyles();
  console.log(video, "video");
  return (
    <Card className={classes.card}>
      {video ? (
        <CardMedia className={classes.videomedia}>
          <video
            style={{ height: 500 }}
            controls
            autoPlay
            playsInline
            muted
            src={video}
          />
        </CardMedia>
      ) : (
        <CardMedia className={classes.media} image={img} title={name} />
      )}
      <div className={classes.overlay}></div>
      <div className={classes.overlay2}></div>
      <div className={classes.details}></div>
      <Typography className={classes.title} variant="h6" gutterBottom>
        {synopsis}
      </Typography>
      <CardActions className={classes.cardActions}>
        <Button href={url} target="_blank" size="small" color="primary">
          Check the link Here ✊🏾
        </Button>
        <Typography varaint="h2" color="textSecondary">
          {used}
        </Typography>
      </CardActions>
    </Card>
  );
};

export default Project;
