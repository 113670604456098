import anime from "../images/anime.png";
import chat from "../images/chat.png";
import memos from "../images/memos.png";
import amazon from "../images/amazon.png";
import IG from "../../videos/instagram.mp4";

const Projects = [
  {
    name: "Chat Bot",
    img: chat,
    used: "React Router, Socket.io, Express, Node, Cors",
    synopsis:
      "Created with a serverless chat that uses React, Express, Node and Socket.IO.",
    url: "https://distracted-northcutt-6563ef.netlify.app/",
  },
  {
    name: "Anime Search",
    synopsis:
      "Built with react and implemented Alan Ai to create search on animes by vocal input. usage of material UI.",
    img: anime,
    used: "Axios, Alan AI, React",
    url: "https://vigilant-newton-381694.netlify.app/",
  },
  {
    name: "Memories",
    used: "Material UI, Redux, MongoDB, Express, Cors, Node",
    synopsis:
      "Built using React, Redux and MongoDB. Lets user upload, like and delete pictures.",
    img: memos,
    url: "https://ecstatic-boyd-0af103.netlify.app/",
  },
  {
    name: "Amazon Clone",
    used: "Firebase Authentication , Stripe , Firebase Cloud Functions",
    synopsis:
      "Amazon Website Clone, FullStack App, Ecommerce site. Lets you move around an operate just like the real thing",
    img: amazon,
    url: "https://clone-e10bb.web.app/",
  },
  {
    name: "Instagram Clone",
    used: "Firebase Database, Redux, React-Native",
    synopsis:
      "Instagram clone done in react native. You can create a profile, add pictures, follow other user and have a live feed from the user.",
    // img: amazon,
    url: "https://github.com/JeffAJohnson22/IGClone",
    video: IG,
  },
];

export default Projects;
