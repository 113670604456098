import React from 'react'
import {BrowserRouter as Router, Route} from 'react-router-dom'
import Home from './components/Home/Home'
import Projects from './components/Projects/Projects'


const App = () => {
  
  return ( 
    <Router>
      <Route path='/' exact component={Home} /> 
      <Route path='/projects' exact component={Projects} />
    </Router>

  )
}

export default App
