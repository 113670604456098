import styled from 'styled-components'
import { Link } from 'react-router-dom'

export const Nav = styled.nav`
  text-align: center;
  z-index:10;
  top: 25px;  
  position: relative;
  
@media screen and (max-width:960px){
  transition: 0.8 all ease
}
`

export const NavbarContainer = styled.div`
    display:flex;
    justify-content: space-around;
    z-index: 1;
`

export const NavLogo = styled(Link)`
color:#d0d1e0;
justify-self: flex-start;
font-size:1.5rem;
display:flex;
font-weight:bold;
text-decoration:none;

`

export const NavLogos = styled.a`
color:#d0d1e0;
justify-self: flex-start;
display:flex;
font-weight:bold;
text-decoration:none;

`